import Vue from 'vue'
import router from './router.js'
import axios from 'axios'
import App from './App.vue'
import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import querystring from 'querystring'
import moment from 'moment'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// let baseUrl = 'https://beauty.yunjike39.com/apis'
let baseUrl = 'https://yjk-api.yunjike39.com/yjb'
// https://beauty.yunjike39.com/apis 生产
// https://yjk-test.yunjike39.com/apis 测试
Vue.prototype.mapKey = '65db811f0e80eb5c52da5d5252fb0a13'// 高德地图key
Vue.prototype.mapKey2 = 'e802d6f3ba80b4b312163dcf5be20b34' // 高德地图密钥
Vue.prototype.isLoginPage = false
Vue.config.productionTip = false
Vue.prototype.$querystring = querystring
Vue.prototype.$moment = moment
Vue.prototype.$axios = axios
Vue.prototype.$baseUrl = baseUrl
Vue.prototype.$headers = {
  'token': window.localStorage.getItem('token')
}
let smFlag = 0
if (window.innerWidth < 700) {
  smFlag = 1
}
Vue.prototype.smFlag = smFlag // 小屏幕标识
axios.defaults.baseURL = '/yjb'
axios.withCredentials = true
axios.timeout = 180000
/**拦截器统*/
axios.interceptors.request.use(
  config => {
      let token = window.localStorage.getItem('token')
      if (token) {
        // Bearer
        config.headers['token'] = token
      }
      return config
  },
  function (error) {
      return Promise.reject(error)
  }
)
/**拦截器验证token失效以及接口错误信息 */
axios.interceptors.response.use(
  response => {
    if (response.status == 500) {
      Message.closeAll()
      Message.error('服务错误');
    }
    if (response.status == 401) {
      Message.closeAll()
      Message.error('令牌过期');
      router.push('/login')
    }
    if (response.status == 200 && response.data.code && response.data.code != 200) {
      Message.closeAll()
      // 接口错误给出错误提示
      Message.error(response.data.msg);
      if (response.data.code == 401) {
        router.push('/login')
      }
    }
    return response;
  },
  error => {
      // debugger
      console.log("error" + error)
      return Promise.reject(error); // 返回接口返回的错误信息
  }
);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
